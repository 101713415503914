import gql from "graphql-tag";

export const Add_LEAD_ENDPOINT = gql`
  mutation insert_leads_endpoint($objects: [leads_endpoint_insert_input!]!) {
    insert_leads_endpoint(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const getLeadsLastHourByQuote = gql`
  subscription {
    leads_last_hr_by_quote {
      count
    }
  }
`;
export const getLeadsLast7DaysByQuote = gql`
  subscription {
    leads_last_7days_by_quote {
      count
    }
  }
`;
export const getLeadsLast30DaysByQuote = gql`
  subscription {
    leads_last_30days_by_quote {
      count
    }
  }
`;
export const getAdminLeadsLastHourByQuote = gql`
  subscription {
    admin_leads_last_hr_by_quote {
      count
    }
  }
`;
export const getAdminLeadsLast7DaysByQuote = gql`
  subscription {
    admin_leads_last_7days_by_quote {
      count
    }
  }
`;
export const getAdminLeadsLast30DaysByQuote = gql`
  subscription {
    admin_leads_last_30days_by_quote {
      count
    }
  }
`;
export const getLeadsLastHourByCatalog = gql`
  subscription {
    leads_last_hr_by_catalog {
      count
    }
  }
`;
export const getAdminLeadsLastHourByCatalog = gql`
  subscription {
    admin_leads_last_hr_by_catalog {
      count
    }
  }
`;
export const getLeadsLast7DaysByCatalog = gql`
  subscription {
    leads_last_7days_by_catalog {
      count
    }
  }
`;
export const getLeadsLast30DaysByCatalog = gql`
  subscription {
    leads_last_30days_by_catalog {
      count
    }
  }
`;
export const getAdminLeadsLast7DaysByCatalog = gql`
  subscription {
    admin_leads_last_7days_by_catalog {
      count
    }
  }
`;
export const getAdminLeadsLast30DaysByCatalog = gql`
  subscription {
    admin_leads_last_30days_by_catalog {
      count
    }
  }
`;
export const getAdminWarrantyClaimsUnderReview = gql`
  subscription {
    admin_warranty_claims_under_review {
      count
    }
  }
`;
export const getAdminWarrantyClaimsApproved = gql`
  subscription {
    admin_warranty_claims_approved {
      count
    }
  }
`;
export const getAdminWarrantyClaimsDenied = gql`
  subscription {
    admin_warranty_claims_denied {
      count
    }
  }
`;
export const getAdminContactRequestsLast30 = gql`
  subscription {
    admin_contact_request_last_hr {
      count
    }
  }
`;

export const getAllDealerRatings = gql`
  subscription {
    survey_rating_aggregate {
      profile_id
      dealer_name
      rating
    }
  }
`;

export const getAllLeads_sub = gql`
  subscription {
    leads_with_dealer_info {
      email
      lead_type
      created_at
      address
      country
      phone
      brand
      name
      fulfilledby
      fulfilledon
    }
  }
`;

export const getAllLeads = gql`
  {
    leads_with_dealer_info(order_by: { created_at: desc }) {
      id
      profile_id
      dealer_name
      lead_id
      lead_type
      created_at
      status
      address
      country
      email
      phone
      heard_about
      magazines
      time_frame
      ownership
      interests
      age
      annual_income
      brand
      lead_date
      name
      options
      fulfilledby
      fulfilledon
      location
    }
  }
`;

export const getCustomerforLetterHead = gql`
  query leads_by_pk($id: uuid!) {
    leads_by_pk(id: $id) {
      profile_id
      name
      address
      country
    }
  }
`;

export const getDealerforLetterRequest = gql`
  query profiles_by_pk($profile_id: String!) {
    profiles_by_pk(profile_id: $profile_id) {
      name
      address
      address2
      phone
      website
      region
    }
  }
`;

export const getNearestDealerToLead = gql`
  query nearest_dealer_to_lead($id: uuid!) {
    nearest_dealer_to_lead(
      where: { id: { _eq: $id } }
      order_by: { distance: asc }
      distinct_on: distance
      limit: 2
    ) {
      name
      address
      address2
      phone
      website
    }
  }
`;

export const getAllDealerWarrantyClaims = gql`
  {
    warranty_claims(order_by: { submittedon: desc }) {
      id
      profile_id
      associate_name
      associate_email
      admin_name
      admin_email
      hull_identification_number
      purchase_date
      brand
      model
      first_name
      last_name
      phone
      email
      warranty_claims_id
      address
      address2
      city
      region
      country
      zipcode
      motor_details
      issues
      repairs
      parts
      photos
      labor_hours
      labor_total
      parts_used
      sublet
      other
      claim_total
      status
      submittedon
      fulfilledon
      comments
      boat_model {
        older_model
      }
    }
  }
`;
export const getAllDealerWarrantyRegistrations = gql`
  {
    warranty_registration(
      order_by: { submittedon: desc }
      where: { status: { _neq: "Deleted" } }
    ) {
      id
      profile_id
      admin_name
      admin_email
      hull_identification_number
      purchase_date
      brand
      model
      first_name
      last_name
      phone
      email
      address
      address2
      city
      region
      country
      zipcode
      motor_details
      accessories
      primary_use
      submittedon
      status
      warranty_id
      boat_model {
        older_model
      }
    }
  }
`;

export const GET_ACTIVE_WARRANTY_REGISTRATION_BY_HID = gql`
  query warranty_registration($hin: String) {
    warranty_registration(
      where: {
        status: { _eq: "New" }
        hull_identification_number: { _eq: $hin }
      }
    ) {
      brand
      model
    }
  }
`;

export const GET_WARRANTY_REGISTRATION = gql`
  query warranty_registration_by_pk($id: uuid!) {
    warranty_registration_by_pk(id: $id) {
      first_name
      last_name
      phone
      email
      profile_id
      hull_identification_number
      brand
      model
      purchase_date
      address
      address2
      comments
    }
  }
`;

export const ADD_WARRANTY_REGISTRATIONS = gql`
  mutation insert_warranty_registration(
    $objects: [warranty_registration_insert_input!]!
  ) {
    insert_warranty_registration(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_WARRANTY_REGISTRATION = gql`
  mutation update_warranty_registration(
    $id: uuid!
    $first_name: String
    $last_name: String
    $phone: String
    $email: String
    $profile_id: String
    $hull_identification_number: String
    $brand: String
    $model: String
    $purchase_date: String
    $address: String
    $address2: String
    $comments: String
    $model_id: uuid
  ) {
    update_warranty_registration(
      where: { id: { _eq: $id } }
      _set: {
        first_name: $first_name
        last_name: $last_name
        phone: $phone
        email: $email
        profile_id: $profile_id
        hull_identification_number: $hull_identification_number
        brand: $brand
        model: $model
        purchase_date: $purchase_date
        address: $address
        address2: $address2
        comments: $comments
        model_id: $model_id
      }
    ) {
      affected_rows
    }
  }
`;

export const SOFT_DELETE_WARRANTY_REGISTRATION = gql`
  mutation updateProfileArchiveDealer($id: uuid!) {
    update_warranty_registration(
      where: { id: { _eq: $id } }
      _set: { status: "Deleted" }
    ) {
      affected_rows
    }
  }
`;

export const getAllDealerOptions = gql`
  {
    profiles(
      where: { role: { _eq: "Dealer" } }
      order_by: { status: asc, name: asc }
    ) {
      company
      profile_id
      name
      status
    }
  }
`;

export const getAllContactRequest = gql`
  {
    contact_requests(order_by: { contact_date: desc }) {
      id
      brand
      first_name
      last_name
      address
      address2
      city
      region
      country
      zipcode
      email
      phone
      comments
      contact_date
      fulfilledby
      fulfilledon
    }
  }
`;

export const getAllSurveys = gql`
  {
    survey(order_by: { survey_date: desc }) {
      id
      profile_id
      dealer_name
      first_name
      last_name
      warranty_id
      first_new_boat
      consider_brands
      consider_brands_yes
      when_right_boat
      first_see_boat
      deciding_factor
      people_on_boat
      use_new_boat
      plan_to_use_new_boat
      water_type_usage
      own_another_boat
      sales_experience
      refer_selling_dealer
      satisfied_model_selection
      satisfied_professionalism
      satisfied_cleanliness
      dealer_explanation
      time_complete_purchase
      new_boat_serviced
      delivery_process
      features_explanation
      dealership_tour
      parts_manager
      service_manager
      answer_questions
      knowledgeable
      sea_trial
      on_water_delivery
      dealer_warranty
      explain_sea_trial
      follow_up
      comments
      survey_date
      satisfied_boat_overall
      satisfied_boat_performance
      satisfied_boat_price
      satisfied_boat_finish
    }
  }
`;

export const getWarranties = gql`
  {
    warranty_registration(
      order_by: { submittedon: desc }
      where: { is_deleted: { _eq: 0 } }
    ) {
      admin_name
      admin_email
      profile_id
      status
      hull_identification_number
      purchase_date
      brand
      model
      first_name
      last_name
      phone
      email
      address
      address2
      city
      region
      country
      zipcode
      motor_details
      accessories
      primary_use
      warranty_id
      submittedon
      id
      is_deleted
      profiles {
        name
      }
    }
  }
`;

export const getAllWarranties = gql`
  query warranty_registration(
    $skip: Int
    $limit: Int
    $where: warranty_registration_bool_exp
  ) {
    warranty_registration(
      order_by: { submittedon: desc }
      offset: $skip
      limit: $limit
      where: $where
    ) {
      admin_name
      admin_email
      profile_id
      status
      hull_identification_number
      purchase_date
      brand
      model
      first_name
      last_name
      phone
      email
      address
      address2
      city
      region
      country
      zipcode
      motor_details
      accessories
      primary_use
      warranty_id
      submittedon
      id
      is_deleted
      profiles {
        name
      }
    }
    warranty_registration_aggregate(
      order_by: { submittedon: desc }
      where: $where
    ) {
      aggregate {
        totalCount: count
      }
    }
  }
`;

// export const getAllWarranties = gql`
//   query warranty_registration($skip: Int, $limit: Int,$search: String) {
//       warranty_registration(offset: $skip, limit: $limit, where: {
//         _or: [
//           {hull_identification_number: {_ilike: $search}},
//           {status: {_ilike: $search}}
//         ]
//       }) {
//         admin_name
//         admin_email
//         profile_id
//         status
//         hull_identification_number
//         purchase_date
//         brand
//         model
//         first_name
//         last_name
//         phone
//         email
//         address
//         address2
//         city
//         region
//         country
//         zipcode
//         motor_details
//         accessories
//         primary_use
//         warranty_id
//         submittedon,
//         id
//         is_deleted
//       }
//       warranty_registration_aggregate(order_by: {submittedon: desc}, where: { status: { _eq: "New" }, is_deleted: { _eq: 0 }}) {
//         aggregate {
//           totalCount: count
//         }
//       }
//     }
// `;

export const getAllNewDealerRequests = gql`
  {
    new_dealer_requests(order_by: { request_date: desc }) {
      id
      dealer_name
      legal_name
      address
      address2
      city
      region
      country
      zipcode
      delivery_address
      delivery_address2
      delivery_city
      delivery_region
      delivery_country
      delivery_zipcode
      mailing_address
      mailing_address2
      mailing_city
      mailing_region
      mailing_country
      mailing_zipcode
      business_phone1
      business_phone2
      business_fax
      business_cell
      business_other
      business_email
      business_sales_email
      business_website
      contact_name_1
      contact_title_1
      contact_name_2
      contact_title_2
      boat_brands_sold
      outboard_brands
      bank_name
      bank_account
      bank_contact
      bank_business_type
      bank_state
      bank_taxid
      bank_resale_taxid
      supplier_name_1
      supplier_phone_1
      supplier_address_1
      supplier_city_1
      supplier_region_1
      supplier_zipcode_1
      supplier_contact_1
      supplier_account_1
      supplier_name_2
      supplier_phone_2
      supplier_address_2
      supplier_city_2
      supplier_region_2
      supplier_zipcode_2
      supplier_contact_2
      supplier_account_2
      dealer_signature
      fulfilledby
      fulfilledon
      request_date
      seachaser
      carolinaskiff
    }
  }
`;

export const deleteNewDealerRequestById = gql`
  mutation delete_new_dealer_requests($id: uuid!) {
    delete_new_dealer_requests(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const getAllWarrantyInquiries = gql`
  {
    warranty_inquiries(order_by: { request_date: desc }) {
      id
      warranty_inquiry_id
      model
      hull_identification_number
      first_name
      last_name
      address
      address1
      city
      country
      region
      zipcode
      email
      phone
      comments
      submittedon
      fulfilledon
      fulfilledby
      request_date
    }
  }
`;

export const getAllWarrantyClaims = gql`
  {
    warranty_claims(
      where: { status: { _eq: "new" } }
      order_by: { submittedon: desc }
    ) {
      id
      warranty_claims_id
      admin_name
      admin_email
      status
      hull_identification_number
      purchase_date
      brand
      model
      first_name
      last_name
      phone
      email
      address
      address2
      city
      region
      country
      zipcode
      labor_hours
      labor_total
      parts_used
      sublet
      other
      claim_total
      submittedon
    }
  }
`;

export const GET_WARRANTY_CLAIM = gql`
  query warranty_claims_by_pk($id: uuid!) {
    warranty_claims_by_pk(id: $id) {
      admin_name
      admin_email
      hull_identification_number
      purchase_date
      first_name
      last_name
      email
      phone
      motor_details
      issues
      repairs
      parts
      labor_hours
      labor_total
      parts_used
      other
      claim_total
      comments
    }
  }
`;

export const UPDATE_WARRANTY_CLAIM = gql`
  mutation update_warranty_claims(
    $id: uuid!
    $admin_name: String
    $admin_email: String
    $hull_identification_number: String
    $purchase_date: String
    $first_name: String
    $last_name: String
    $email: String
    $phone: String
    $motor_details: String
    $issues: String
    $repairs: String
    $parts: String
    $labor_hours: String
    $labor_total: String
    $parts_used: String
    $other: String
    $claim_total: String
    $comments: String
  ) {
    update_warranty_claims(
      where: { id: { _eq: $id } }
      _set: {
        admin_name: $admin_name
        admin_email: $admin_email
        hull_identification_number: $hull_identification_number
        purchase_date: $purchase_date
        first_name: $first_name
        last_name: $last_name
        email: $email
        phone: $phone
        motor_details: $motor_details
        issues: $issues
        repairs: $repairs
        parts: $parts
        labor_hours: $labor_hours
        labor_total: $labor_total
        parts_used: $parts_used
        other: $other
        claim_total: $claim_total
        comments: $comments
      }
    ) {
      affected_rows
    }
  }
`;

export const getAllBoatShowRequests = gql`
  {
    boat_shows {
      id
      name
      profile_id
      address
      address2
      city
      zipcode
      region
      country
      description
      created_at
      modified_at
      start_date
      end_date
      dealer
    }
  }
`;

export const getAllAvailableDealersSubscription = gql`
  subscription {
    profiles(
      where: { status: { _eq: "active" }, role: { _eq: "Dealer" } }
      order_by: { name: asc }
    ) {
      profile_id
      name
      email
    }
  }
`;

export const getAllMediaSubscription = gql`
  subscription {
    media(order_by: { position: asc }) {
      album_name
      brand
      created
      position
      id
      media_items_aggregate {
        aggregate {
          count
        }
      }
      media_items {
        media_id
        name
        preview_link
        updated_at
        id
        download_link
        created_at
        media_type
      }
    }
  }
`;

export const getAllMediaBrands = gql`
  {
    media(order_by: { created: desc }) {
      id
      brand
    }
  }
`;
export const getAllMediaAlbumNamesSubscription = gql`
  subscription {
    media(order_by: { created: desc }) {
      id
      album_name
    }
  }
`;
export const ADD_MEDIA = gql`
  mutation insert_media($objects: [media_insert_input!]!) {
    insert_media(objects: $objects) {
      returning {
        id
      }
    }
  }
`;
export const ADD_MEDIA_ITEMS = gql`
  mutation insert_media_items($objects: [media_items_insert_input!]!) {
    insert_media_items(objects: $objects) {
      returning {
        id
      }
    }
  }
`;
export const DELETE_MEDIA_ITEM_BY_ID = gql`
  mutation delete_media_items_by_id($id: uuid!) {
    delete_media_items(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export const UPDATE_MEDIA_BY_ID = gql`
  mutation update_media($id: uuid!, $position: Int) {
    update_media(where: { id: { _eq: $id } }, _set: { position: $position }) {
      affected_rows
    }
  }
`;
export const DELETE_MEDIA_BY_ID = gql`
  mutation delete_media_by_id($id: uuid!) {
    delete_media(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export const UPDATE_DEALER_USER = gql`
  mutation update_profiles(
    $profile_id: String!
    $name: String
    $permissions: json
    $phone: String
    $region: String
    $role: String
    $zipcode: String
    $location: geography
    $has_skiff: Boolean
    $has_seachaser: Boolean
    $has_funchaser: Boolean
    $email: String!
    $country: String
    $company: String
    $city: String
    $address: String
    $address2: String
    $business_email: String
  ) {
    update_profiles(
      where: { profile_id: { _eq: $profile_id } }
      _set: {
        address: $address
        address2: $address2
        city: $city
        company: $company
        country: $country
        email: $email
        has_funchaser: $has_funchaser
        has_seachaser: $has_seachaser
        has_skiff: $has_skiff
        location: $location
        name: $name
        permissions: $permissions
        phone: $phone
        region: $region
        role: $role
        business_email: $business_email
        zipcode: $zipcode
      }
    ) {
      affected_rows
    }
  }
`;
export const UPDATE_DEALER_PROFILE = gql`
  mutation updateDealerProfile(
    $profile_id: String!
    $name: String
    $email: String!
    $phone: String
    $company: String
    $address: String
    $address2: String
    $city: String
    $country: String
    $region: String
    $role: String
    $zipcode: String
    $business_email: String
    $location: geography
  ) {
    update_profiles(
      where: {
        profile_id: { _eq: $profile_id }
        _and: { role: { _eq: "Dealer" } }
      }
      _set: {
        name: $name
        email: $email
        phone: $phone
        company: $company
        address: $address
        address2: $address2
        city: $city
        country: $country
        region: $region
        zipcode: $zipcode
        business_email: $business_email
      }
    ) {
      affected_rows
    }
  }
`;
export const UPDATE_ADMIN_USER = gql`
  mutation update_profiles(
    $profile_id: String!
    $name: String
    $permissions: json
    $email: String!
  ) {
    update_profiles(
      where: { profile_id: { _eq: $profile_id } }
      _set: { name: $name, permissions: $permissions, email: $email }
    ) {
      affected_rows
    }
  }
`;

export const getAllDealers = gql`
  subscription profiles($id: String!) {
    profiles(
      order_by: { created_at: desc_nulls_last }
      where: { parent_admin: { _eq: $id }, _and: { role: { _eq: "Dealer" } } }
    ) {
      name
      parent_admin
      permissions
      phone
      profile_id
      region
      role
      website
      zipcode
      location
      has_skiff
      has_seachaser
      has_funchaser
      email
      created_at
      country
      company
      city
      address2
      address
      status
    }
  }
`;

export const getDealers = gql`
  subscription profiles( $status: String!) {
    profiles(
      order_by: { created_at: desc_nulls_last }
      where: {
        role: { _eq: "Dealer" }
        status: { _like: $status }
      }
    ) {
      name
      parent_admin
      permissions
      phone
      profile_id
      region
      role
      website
      zipcode
      location
      has_skiff
      has_seachaser
      has_funchaser
      email
      created_at
      country
      company
      city
      address2
      address
      status
    }
  }
`;

export const getAllUsers = gql`
  subscription {
    profiles(
      order_by: { created_at: desc_nulls_last }
      where: { role: { _eq: "Admin" } }
    ) {
      name
      parent_admin
      permissions
      phone
      profile_id
      region
      role
      website
      zipcode
      location
      has_skiff
      has_seachaser
      has_funchaser
      email
      created_at
      country
      company
      city
      address2
      address
    }
  }
`;
export const ADD_ADMIN_USER = gql`
  mutation insert_profiles($objects: [profiles_insert_input!]!) {
    insert_profiles(objects: $objects) {
      returning {
        profile_id
      }
    }
  }
`;

export const ADD_MYDEALER_USER = gql`
  mutation insert_profiles($objects: [profiles_insert_input!]!) {
    insert_profiles(objects: $objects) {
      returning {
        profile_id
      }
    }
  }
`;

export const ARCHIVE_MYDEALER_USER = gql`
  mutation updateProfileArchiveDealer(
    $profile_id: String!
    $status: String!
    $updated_at: timestamptz!
  ) {
    update_profiles(
      where: { profile_id: { _eq: $profile_id } }
      _set: { status: $status, updated_at: $updated_at }
    ) {
      affected_rows
    }
  }
`;

export const GET_USER_INFO = gql`
  query profiles($id: String!) {
    profiles_by_pk(profile_id: $id) {
      name
      parent_admin
      permissions
      phone
      profile_id
      region
      role
      website
      zipcode
      location
      has_skiff
      has_seachaser
      has_funchaser
      email
      created_at
      country
      company
      city
      address2
      address
      business_email
    }
  }
`;
export const ADD_NEW_CAREER = gql`
  mutation insert_careers($objects: [careers_insert_input!]!) {
    insert_careers(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const getAllApprovedCareers = gql`
  subscription {
    careers(where: { status: { _eq: "Active" } }) {
      id
      title
      description
      store_location
      salary_range
      submittedon
      date_needed
      status
    }
  }
`;

export const getAllPendingCareers = gql`
  subscription {
    careers(where: { status: { _eq: "Pending" } }) {
      id
      title
      description
      store_location
      salary_range
      submittedon
      date_needed
      status
    }
  }
`;

export const getClosedCareers = gql`
  subscription {
    careers(where: { status: { _eq: "Closed" } }) {
      id
      title
      description
      store_location
      salary_range
      submittedon
      date_needed
      status
    }
  }
`;

export const GET_BOAT_MODELS = gql`
  query boat_models($skip: Int, $limit: Int) {
    boat_models(
      offset: $skip
      limit: $limit
      order_by: { older_model: asc, model: asc }
    ) {
      model
      older_model
      id
    }
    boat_models_aggregate {
      aggregate {
        totalCount: count
      }
    }
  }
`;

export const GET_BOAT_MODEL = gql`
  query boat_models_by_pk($id: uuid!) {
    boat_models_by_pk(id: $id) {
      model
      older_model
      id
    }
  }
`;

export const ADD_BOAT_MODEL = gql`
  mutation insert_boat_models($objects: [boat_models_insert_input!]!) {
    insert_boat_models(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const DELETE_BOAT_MODEL = gql`
  mutation delete_boat_models($id: uuid!) {
    delete_boat_models(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_BOAT_MODEL = gql`
  mutation update_boat_models(
    $id: uuid!
    $model: String
    $older_model: Boolean
  ) {
    update_boat_models(
      where: { id: { _eq: $id } }
      _set: { model: $model, older_model: $older_model }
    ) {
      affected_rows
    }
  }
`;

export const GET_AWS_KEY = gql`
  query keys($id: uuid!) {
    keys_by_pk(id: $id) {
      name
      value
    }
  }
`;

export const getAllMessages = gql`
  subscription {
    admin_messages(order_by: { created_at: desc }) {
      id
      profile_id
      message
      created_at
      updated_at
    }
  }
`;

export const getMessage = gql`
  query admin_messages($id: id!) {
    admin_messages(where: { id: { _eq: $id } }) {
      id
      profile_id
      message
      created_at
      updated_at
    }
  }
`;

export const ADD_ADMIN_MESSAGE = gql`
  mutation insert_admin_messages($objects: [admin_messages_insert_input!]!) {
    insert_admin_messages(objects: $objects) {
      returning {
        id
        message
        profile_id
        updated_at
        created_at
      }
    }
  }
`;

export const UPDATE_ADMIN_MESSAGE = gql`
  mutation update_admin_messages(
    $id: uuid!
    $message: String!
    $updated_at: timestamptz!
  ) {
    update_admin_messages(
      where: { id: { _eq: $id } }
      _set: { message: $message, updated_at: $updated_at }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_ADMIN_MESSAGE = gql`
  mutation delete_admin_messages($id: uuid!) {
    delete_admin_messages(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const ADD_MESSAGE_READ_STATUS = gql`
  mutation insert_admin_message_status(
    $objects: [admin_message_status_insert_input!]!
  ) {
    insert_admin_message_status(objects: $objects) {
      affected_rows
    }
  }
`;

export const GET_ALL_USER_DATA = gql`
  query profiles {
    profiles {
      profile_id
      name
      company
    }
  }
`;

export const GET_DEALER_MESSAGE = gql`
  query admin_messages($dealerId: Int!) {
    admin_messages {
      id
      message
      profile_id
      message_status(where: { dealer_id: { _eq: $dealerId } }) {
        id
        dealer_id
        message_id
      }
    }
  }
`;

export const GET_USER_MESSAGE_STATUS = gql`
  subscription ($userId: String!) {
    admin_message_status(
      where: { user_id: { _eq: $userId } }
      order_by: { created_at: desc }
    ) {
      created_at
      id
      is_read
      user_id
      admin_message {
        id
        message
      }
    }
  }
`;

export const UPDATE_MESSAGE_MESSAGE_STATUS = gql`
  mutation update_admin_message_status(
    $id: uuid!
    $is_read: Int
    $read_at: timestamptz!
    $updated_at: timestamptz!
  ) {
    update_admin_message_status(
      where: { id: { _eq: $id } }
      _set: { is_read: $is_read, read_at: $read_at, updated_at: $updated_at }
    ) {
      affected_rows
    }
  }
`;

export const DELETE_ADMIN_MESSAGE_STATUS = gql`
  mutation delete_admin_message_status($id: uuid!) {
    delete_admin_message_status(where: { message_id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_MAIL_LEAD = gql`
  mutation update_profiles(
    $profile_id: String!
    $crm_mail_event_trigger: timestamptz = "now()"
  ) {
    update_profiles(
      where: { profile_id: { _eq: $profile_id } }
      _set: { crm_mail_event_trigger: $crm_mail_event_trigger }
    ) {
      affected_rows
    }
  }
`;

export const getLeadSendCount = gql`
  subscription {
    leads_aggregate(
      where: {
        crm_mail_lead_sent: { _eq: 0 }
        profile_id: { _eq: "auth0|5e4186946922b70f18a7b161" }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const MAIL_LEAD_TO_DELAER = gql`
  mutation update_profiles(
    $profile_id: String!
    $mail_event_trigger: timestamptz = "now()"
  ) {
    update_profiles(
      where: { profile_id: { _eq: $profile_id } }
      _set: { mail_event_trigger: $mail_event_trigger }
    ) {
      affected_rows
    }
  }
`;

export const ADD_INVENTORY = gql`
  mutation insert_inventory($objects: [inventory_insert_input!]!) {
    insert_inventory(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const GET_INVENTORY = gql`
  subscription {
    inventory(
      where: { is_deleted: { _eq: False } }
      order_by: { created_at: desc }
    ) {
      id
      beam
      boat_name
      boat_price
      boat_weight
      year
      created_at
      description
      draft
      fuel_capacity
      gunnel_height
      transom_size
      length_overall_FT
      length_overall_IN
      maximum_hp
      maximum_person
      maximum_weight
      created_at
      updated_at
      profile_id
      site
      hull_identification_number
      profile {
        name
      }
      assign_dealer
      assign_dealer_profile {
        name
      }
      updated_by
      updated_by_profile {
        name
      }
    }
  }
`;

export const GET_ALL_INVENTORY = gql`
  {
    inventory(order_by: { created_at: desc }) {
      id
      is_deleted
      hull_identification_number
      assign_dealer
      boat_name
      site
    }
  }
`;

export const UPDATE_INVENTORY = gql`
  mutation update_inventory(
    $id: uuid!
    $beam: numeric
    $boat_name: String
    $boat_price: String
    $boat_weight: numeric
    $year: numeric
    $description: String
    $draft: numeric
    $fuel_capacity: numeric
    $gunnel_height: numeric
    $transom_size: numeric
    $length_overall_FT: numeric
    $length_overall_IN: numeric
    $maximum_hp: numeric
    $maximum_person: numeric
    $maximum_weight: numeric
    $profile_id: String
    $assign_dealer: String
    $site: String
    $hull_identification_number: String
    $updated_by: String
  ) {
    update_inventory(
      where: { id: { _eq: $id } }
      _set: {
        beam: $beam
        boat_name: $boat_name
        boat_price: $boat_price
        boat_weight: $boat_weight
        year: $year
        description: $description
        draft: $draft
        fuel_capacity: $fuel_capacity
        gunnel_height: $gunnel_height
        transom_size: $transom_size
        length_overall_FT: $length_overall_FT
        length_overall_IN: $length_overall_IN
        maximum_hp: $maximum_hp
        maximum_person: $maximum_person
        maximum_weight: $maximum_weight
        profile_id: $profile_id
        assign_dealer: $assign_dealer
        site: $site
        hull_identification_number: $hull_identification_number
        updated_by: $updated_by
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_INVENTORY_STATUS = gql`
  mutation update_inventory($id: uuid!, $is_deleted: Boolean) {
    update_inventory(
      where: { id: { _eq: $id } }
      _set: { is_deleted: $is_deleted }
    ) {
      affected_rows
    }
  }
`;

export const GET_INVENTORY_BY_ID = gql`
  query inventory_by_pk($id: uuid!) {
    inventory_by_pk(id: $id) {
      id
      beam
      boat_name
      boat_price
      boat_weight
      year
      description
      draft
      fuel_capacity
      gunnel_height
      transom_size
      length_overall_FT
      length_overall_IN
      maximum_hp
      maximum_person
      maximum_weight
      created_at
      updated_at
      profile_id
      site
      hull_identification_number
      profile {
        name
      }
      assign_dealer
      assign_dealer_profile {
        name
      }
      inventory_images {
        id
        image_path
      }
      updated_by
      updated_by_profile {
        name
      }
    }
  }
`;

export const DELETE_INVENTORY = gql`
  mutation delete_inventory($id: uuid!) {
    delete_inventory(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const DELETE_INVENTORY_IMAGES = gql`
  mutation delete_inventory_images($inventory_id: uuid!) {
    delete_inventory_images(where: { inventory_id: { _eq: $inventory_id } }) {
      affected_rows
    }
  }
`;

export const ADD_INVENTORY_IMAGES = gql`
  mutation insert_inventory_images(
    $objects: [inventory_images_insert_input!]!
  ) {
    insert_inventory_images(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export const GET_BOATS_NAME = gql`
  {
    boat_models(order_by: { model: asc }) {
      id
      model
    }
  }
`;

export const DELETE_INVENTORY_IMAGES_BY_IDS = gql`
  mutation deleteInventoryImagesByIds($ids: [uuid!]!) {
    delete_inventory_images(where: { id: { _in: $ids } }) {
      affected_rows
    }
  }
`;

export const GET_INVENTORY_DEALER = gql`
  subscription inventory($assign_dealer: String, $profile_id: String) {
    inventory(
      order_by: { created_at: desc }
      where: {
        is_deleted: { _eq: False }
        _or: [
          { assign_dealer: { _eq: $assign_dealer } }
          { profile_id: { _eq: $profile_id } }
        ]
      }
    ) {
      id
      beam
      boat_name
      boat_price
      boat_weight
      year
      created_at
      description
      draft
      fuel_capacity
      gunnel_height
      transom_size
      length_overall_FT
      length_overall_IN
      maximum_hp
      maximum_person
      maximum_weight
      created_at
      updated_at
      profile_id
      site
      hull_identification_number
      profile {
        name
      }
      assign_dealer
      assign_dealer_profile {
        name
      }
      updated_by
      updated_by_profile {
        name
      }
    }
  }
`;

export const GET_INVENTORY_DEALER_QUERY = gql`
  query inventory($assign_dealer: String, $profile_id: String) {
    inventory(
      order_by: { created_at: desc }
      where: {
        is_deleted: { _eq: False }
        _or: [
          { assign_dealer: { _eq: $assign_dealer } }
          { profile_id: { _eq: $profile_id } }
        ]
      }
    ) {
      id
      beam
      boat_name
      boat_price
      boat_weight
      year
      created_at
      description
      draft
      fuel_capacity
      gunnel_height
      transom_size
      length_overall_FT
      length_overall_IN
      maximum_hp
      maximum_person
      maximum_weight
      created_at
      updated_at
      profile_id
      site
      hull_identification_number
      profile {
        name
      }
      assign_dealer
      assign_dealer_profile {
        name
      }
      updated_by
      updated_by_profile {
        name
      }
    }
  }
`;

export const GET_All_BOAT_ORDER = gql`
  query order_a_boat($skip: Int, $limit: Int, $searchTerm: String!) {
    order_a_boat(
      limit: $limit
      offset: $skip
      order_by: { created_at: desc }
      where: {
        _or: [
          { profile: { name: { _ilike: $searchTerm } } }
          { profile: { email: { _ilike: $searchTerm } } }
          { brand: { _ilike: $searchTerm } }
        ]
      }
    ) {
      id
      grand_total
      dealer_id
      created_at
      total_qty
      brand
      profile {
        email
        name
        address
        city
        phone
        zipcode
        updated_at
      }
    }
    order_a_boat_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_BOAT_DETAIL = gql`
  query order_a_boat_details($order_a_boat_id: uuid!) {
    order_a_boat_details(
      where: { order_a_boat_id: { _eq: $order_a_boat_id } }
    ) {
      id
      brand
      color
      model
      motor_package
      options
      order_a_boat_id
      specifications
      qty
      total
    }
  }
`;

export const GET_NEW_BOAT_ORDER_DETAIL = gql`
  query order_a_boat(
    $skip: Int
    $limit: Int
    $searchTerm: String!
    $dealer_id: String!
  ) {
    order_a_boat(
      limit: $limit
      offset: $skip
      order_by: { created_at: desc }
      where: {
        dealer_id: { _eq: $dealer_id }
        _or: [
          { profile: { name: { _ilike: $searchTerm } } }
          { profile: { email: { _ilike: $searchTerm } } }
          { brand: { _ilike: $searchTerm } }
        ]
      }
    ) {
      id
      grand_total
      dealer_id
      created_at
      total_qty
      brand
      profile {
        email
        name
        address
        city
        phone
        zipcode
        updated_at
      }
    }
    order_a_boat_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const GET_ASSIGNED_DEALERS = gql`
  query AssignedDealers($profileId: String!) {
    sales_assign(where: { profile_id: { _eq: $profileId } }) {
      assign_user_id
      assign_profiles {
        company
        name
        profile_id
        status
      }
    }
  }
`;

export const GET_INVENTORY_SALES = gql`
  subscription InventorySales($ids: [String!]!, $profile_id: String) {
    inventory(
      where: {
        is_deleted: { _eq: False }
        _or: [
          { assign_dealer: { _in: $ids } }
          { profile_id: { _eq: $profile_id } }
        ]
      }
      order_by: { created_at: desc }
    ) {
      id
      beam
      boat_name
      boat_price
      boat_weight
      year
      created_at
      description
      draft
      fuel_capacity
      gunnel_height
      transom_size
      length_overall_FT
      length_overall_IN
      maximum_hp
      maximum_person
      maximum_weight
      created_at
      updated_at
      profile_id
      site
      hull_identification_number
      profile {
        name
      }
      assign_dealer
      assign_dealer_profile {
        name
      }
      updated_by
      updated_by_profile {
        name
      }
    }
  }
`;

export const ADD_LEAD_FAILURE = gql`
  mutation insert_lead_import_fail($objects: [lead_import_fail_insert_input!]!) {
    insert_lead_import_fail(objects: $objects) {
      returning {
        id
      }
    }
  }
`;
