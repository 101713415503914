import React, { useState } from "react";
import { Mutation } from "react-apollo";
import { Button } from "@material-ui/core";
import { Add_LEAD_ENDPOINT, ADD_LEAD_FAILURE, getAllLeads_sub } from "queries";
import { CircularProgress } from "@material-ui/core";
import { toast } from "react-toastify";
import Papa from "papaparse";
import WarrantyErrorModal from "../WarrantyRegistration/WarrantyErrorModal";
import { withRouter } from "react-router-dom";
import { useSubscription } from "@apollo/client";
import auth from "Auth";

function LeadImport() {
  const [open, setOpen] = useState(false);
  const [inputKey, setInputKey] = useState(Date.now());
  const [errors, setErrors] = useState([]);
  const [file, setFile] = useState(null);

  const { data: leadData, loading: leadLoading } =
    useSubscription(getAllLeads_sub);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      setErrors([]);
    } else {
      setFile(null);
    }
  };

  const requiredFields = [
    "Lead Type",
    "Created At",
    "Country",
    "Email",
    "Phone",
    "Brand",
    "Name",
    "Fulfilledby",
    "Fulfilledon",
    "PostalCode",
  ];

  function validateISODate(dateString) {
    const isoFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    if (!isoFormatRegex.test(dateString)) {
      return { valid: false, type: "format" };
    }
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return { valid: false, type: "value" };
    }
    return { valid: true };
  }

  const validateRow = (row, rowIndex) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const allFieldsEmpty = requiredFields.every((field) => !row?.[field]);
    const fulfilledOnDate = new Date(row["Fulfilledon"]);
    const createdAtDate = new Date(row?.["Created At"]);
    const valueValidation = validateISODate(row["Created At"]);
    const fulFillValueValidation = validateISODate(row["Fulfilledon"]);

    let rowErrors = [];
    if (allFieldsEmpty) {
    } else {
      requiredFields.forEach((field) => {
        if (!row?.[field]) {
          rowErrors.push({
            row: rowIndex + 2,
            field,
            message: `${field} is required`,
          });
        }
      });

      if (row?.["Email"] && !emailRegex.test(row["Email"])) {
        rowErrors.push({
          row: rowIndex + 2,
          field: "Email",
          message: "Email format is invalid",
        });
      }
      // if (
      //   row?.["Email"] &&
      //   leadData?.leads_with_dealer_info
      //     ?.find(
      //       (i) =>
      //         i?.email?.toLowerCase() === row?.["Email"]?.toLowerCase() &&
      //         i?.lead_type?.toLowerCase() ===
      //           row?.["Lead Type"]?.toLowerCase() &&
      //         i?.country?.toLowerCase() === row?.["Country"]?.toLowerCase() &&
      //         i?.phone?.replace(/\D/g, "") ===
      //           row?.["Phone"]?.replace(/\D/g, "") &&
      //         i?.brand?.toLowerCase() === row?.["Brand"]?.toLowerCase() &&
      //         i?.name?.toLowerCase() === row?.["Name"]?.toLowerCase() &&
      //         i?.fulfilledby?.toLowerCase() ===
      //           row?.["Fulfilledby"]?.toLowerCase() &&
      //         moment(i?.created_at).format("YYYY-MM-DD") ===
      //           moment(row?.["Created At"]).format("YYYY-MM-DD") &&
      //         moment(i?.fulfilledon).format("YYYY-MM-DD") ===
      //           moment(row?.["Fulfilledon"]).format("YYYY-MM-DD")
      //     )
      //     ?.email?.toLowerCase() === row?.["Email"]?.toLowerCase()
      // ) {
      //   rowErrors.push({
      //     row: rowIndex + 2,
      //     field: "Lead",
      //     message: "Lead is already exists",
      //   });
      // }
      if (!valueValidation.valid) {
        let message = "";
        if (valueValidation.type === "format") {
          message =
            "Created At must be in ISO 8601 format (YYYY-MM-DDTHH:mm:ss.sssZ)";
        } else if (valueValidation.type === "value") {
          message = "Created At contains an invalid date value.";
        }

        rowErrors.push({
          row: rowIndex + 2,
          field: "Created At",
          message: message,
        });
      }
      if (!fulFillValueValidation.valid) {
        let message = "";
        if (fulFillValueValidation.type === "format") {
          message =
            "Fulfilled on At must be in ISO 8601 format (YYYY-MM-DDTHH:mm:ss.sssZ)";
        } else if (fulFillValueValidation.type === "value") {
          message = "Fulfilled on contains an invalid date value.";
        }

        rowErrors.push({
          row: rowIndex + 2,
          field: "Fulfilledon",
          message: message,
        });
      }
      if (fulfilledOnDate < createdAtDate) {
        rowErrors.push({
          row: rowIndex + 2,
          field: "Fulfilledon",
          message: "Fulfilled On must be greater than or equal to Created At",
        });
      }
    }
    return rowErrors;
  };

  const filterEmptyEntries = (dataArray) => {
    return dataArray.filter((item) => {
      const keys = Object.keys(item);
      return !keys.every((key) => item[key] === "");
    });
  };

  const handleUpload = (addLeadEndpoint, addLeadFailure) => {
    if (!file) {
      toast.error("Please select a file before uploading.");
    } else {
      Papa.parse(file, {
        complete: async (results) => {
          const filteredResults = filterEmptyEntries(results?.data);
          let newErrors = [];
          let validData = [];
          let newData = []
          filteredResults.forEach((row, index) => {
            const rowErrors = validateRow(row, index);
            if (rowErrors?.length === 0) {
              validData.push({
                lead_type: row?.["Lead Type"],
                created_at: row?.["Created At"],
                address: `${row?.["Address"]} ${row?.["City"]} ${row?.["State"]} ${row?.["Country"]} ${row?.["PostalCode"]}`,
                country: row?.["Country"],
                email: row?.["Email"],
                phone: row?.["Phone"],
                brand: row?.["Brand"],
                name: row?.["Name"],
                fulfilledby: row?.["Fulfilledby"],
                fulfilledon: row?.["Fulfilledon"],
                lead_date: row?.["Created At"],
                dealer_id: row?.["Brand"]?.toLowerCase()?.replaceAll(" ", ""),
              });
            } else {
              newErrors = newErrors.concat(rowErrors);
              rowErrors.forEach((error) => {
                newData.push({
                  row: index + 1,
                  field: error.field,
                  message: error.message,
                  email: row?.["Email"] || "-",
                });
              });
            }
          });

          const LeadDetail = {
            lead_success: JSON.stringify(validData?.map((item) => item?.email)),
            lead_fail: JSON.stringify(newData),
            created_by: auth.getSub(),
            created_by_email: auth.getEmail(),
          };
         
          setErrors(newErrors);
          if (newErrors?.length === 0 && validData?.length > 0) {
            try {
              await addLeadEndpoint({
                variables: {
                  objects: validData,
                },
              })
                .then(async (res) => {
                  addLeadFailure({
                    variables: {
                      objects: LeadDetail,
                    },
                  });
                  toast.success("Files uploaded successfully.");
                  window.location.pathname = "/admin-leads";
                  // history.replace(history.location.pathname);
                  setOpen(false);
                  setInputKey(Date.now());
                  setErrors([]);
                  setFile(null);
                })
                .catch((err) => {
                  toast.error(
                    err?.graphQLErrors?.[0]?.message ||
                      "File upload error: Please correct the entries in your sheet and upload again."
                  );
                });
            } catch (e) {
              // toast.error(e?.message?.toString() || "Internal server error");
            }
          } else {
            if (validData?.length > 0) {
              try {
                await addLeadEndpoint({
                  variables: {
                    objects: validData,
                  },
                })
                  .then(async (res) => {
                    addLeadFailure({
                      variables: {
                        objects: LeadDetail,
                      },
                    });
                    toast.success(
                      `${validData?.length} Files were uploaded successfully.`
                    );
                  })
                  .catch((err) => {
                    toast.error(
                      err?.graphQLErrors?.[0]?.message ||
                        "File upload error: Please correct the entries in your sheet and upload again."
                    );
                  });
              } catch (e) {
                // toast.error(e?.message?.toString() || "Internal server error");
              }
            } else if (newErrors?.length > 0) {
              addLeadFailure({
                variables: {
                  objects: LeadDetail,
                },
              });
              toast.error(
                "File upload error: Please correct the entries in your sheet and upload again."
              );
            } else if (validData?.length === 0) {
              toast.error("File upload error: Record not found.");
            } else {
              console.log("ERROR");
            }
            setOpen(true);
          }
        },
        header: true,
      });
    }
  };

  return (
    <>
      <WarrantyErrorModal
        errors={errors}
        open={open}
        setOpen={setOpen}
        setErrors={setErrors}
        setFile={setFile}
        setInputKey={setInputKey}
      />
      <Mutation mutation={Add_LEAD_ENDPOINT}>
        {(addLeadEndpoint, { loading, called, error }) => {
          return (
            <Mutation mutation={ADD_LEAD_FAILURE}>
              {(addLeadFailure, { loading, called, error }) => {
                return (
                  <div className="file_upload">
                    <input
                      type="file"
                      onChange={(event) => handleFileChange(event)}
                      accept=".csv"
                      key={inputKey}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginRight: "10px" }}
                      disabled={
                        leadLoading ||
                        !file ||
                        leadData?.leads_with_dealer_info?.length < 1
                      }
                      onClick={() =>
                        handleUpload(addLeadEndpoint, addLeadFailure)
                      }
                    >
                      {loading ? (
                        <>
                          <CircularProgress
                            style={{
                              color: "#fff",
                              height: "15px",
                              width: "15px",
                              margin: "0px 8px 0px 0px",
                            }}
                          />
                          Leads Import
                        </>
                      ) : (
                        "Leads Import"
                      )}
                    </Button>
                  </div>
                );
              }}
            </Mutation>
          );
        }}
      </Mutation>
    </>
  );
}
export default withRouter(LeadImport);
